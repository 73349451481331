import React, { } from "react";
import { Grid } from "@mui/material";
import { NewsFeed, Project, ProjectMember } from "../../contracts/contracts";
import ProjectDetailTopBar from "../../component/projectComponents/ProjectDetailTopBar";
import ProjectDetailContent from "../../component/projectComponents/ProjectDetailContent";

type props = {
    project: Project | undefined;
    projectMember?: ProjectMember | undefined;
    onJoinProject: (project?: Project) => Promise<void>,
    onFollowProject: (project?: Project) => Promise<void>,
    onToggleCheckinProject: (project?: Project, checkedInStatusOverride?: boolean | undefined) => Promise<void>,
    newsFeeds?: NewsFeed[]
}

const ProjectDetailsView: React.FC<props> = ({
    project,
    projectMember,
    onJoinProject,
    onFollowProject,
    onToggleCheckinProject,
    newsFeeds,
}) => {

    return (<>
        <Grid container item xs={12} spacing={1}>
            <ProjectDetailTopBar project={project} />
            <ProjectDetailContent
                project={project}
                projectMember={projectMember}
                onJoinProject={onJoinProject}
                onFollowProject={onFollowProject}
                onToggleCheckinProject={onToggleCheckinProject}
                newsFeeds={newsFeeds} />
        </Grid>
    </>);
}

export default ProjectDetailsView;
